<template>
  <div id="theories-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead mb-5">
      {{ activeRoute.description }}
    </p>

    <router-link to="/theories" class="btn btn-primary mb-4">Zurück zu deinen Theorien</router-link>

    <div class="card">
      <div class="card-header">
        <h3>Deine neue Theorie</h3>
      </div>
      <div class="card-body">
        <h4>Beschreibe deine Theorie</h4>
        <div class="form-group">
          <label>Name der Theorie:</label>
          <input type="text" v-model="newTheory.title" placeholder="Name deiner Theorie" class="form-control">
        </div>

        <div class="form-group">
          <label>Beschreibung:</label>
          <textarea v-model="newTheory.description" placeholder="Eine kurze Beschreibung was deine Theorie grundsätzlich bewirkt" class="form-control"></textarea>
        </div>

        <hr>

        <h4>Grundlagen deiner Theorie</h4>
        <div class="form-group">
          <label>Annahmen:</label>
          <div class="row">
            <div v-for="(assumption, index) in newTheory.assumptions" class="col-sm-6 col-md-4 mb-3" :key="index">
              <div class="card h-100">
                <div class="card-body">
                  {{ assumption }}
                </div>
                <div @click="removeProp('assumptions', index)" class="delete-button"></div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 mb-3">
              <div class="card h-100">
                <div class="card-body">
                  <label>Neu:</label>
                  <input type="text" @change="addProp('assumptions', $event)" class="form-control">
                </div>
              </div>
            </div>
          </div>

          <label>Beispiele:</label>
          <div class="row">
            <div v-for="(example, index) in newTheory.examples" class="col-sm-6 col-md-4 mb-3" :key="index">
              <div class="card h-100">
                <div class="card-body">
                  {{ example }}
                </div>
                <div @click="removeProp('examples', index)" class="delete-button"></div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 mb-3">
              <div class="card h-100">
                <div class="card-body">
                  <label>Neu:</label>
                  <input type="text" @change="addProp('examples', $event)" class="form-control">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button @click="addTheory" class="btn btn-success">Theorie speichern</button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'TheoriesEdit',
  components: {
  },
  data() {
    return {
      newTheory: {
        id: "",
        title: "",
        description: "",
        icon: "",
        color: "",
        priority: 0,
        template: "",
        examples: [],
        assumptions: [],
        validations: [],
      },
      newAssumption: {},
      newExample: {},
    }
  },
  computed: {
    templateTheories() {
      return this.$store.state.templateTheories.filter(template => !this.project.theories.find(theory => theory.template === template.title));
    },
    theories() {
      return this.project.theories;
    }
  },
  methods: {
    addTheory() {
      let temp = JSON.parse(JSON.stringify(this.newTheory));
      temp.id = this.generateId();

      this.project.theories.push(temp);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'theories', data: this.project.theories})
          .then(() => {
            this.$router.push("/theories/" + temp.id);
          });
    },
    addProp(prop, $event) {
      this.newTheory[prop].push($event.target.value);
      $event.target.value = "";
      this.$forceUpdate();
    },
    removeProp(prop, index) {
      this.newTheory[prop].splice(index, 1);
    }
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.newTheory = this.project.theories.find(theory => theory.id === this.$route.params.id);
    } else {
      this.newTheory.id = this.generateId();
    }
  }
}
</script>
